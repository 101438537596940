.project-card {
  background-color: #f8f9fa;
  border-radius: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  margin-bottom: 1.5rem;
}

.project-card:hover {
  transform: translateY(-5px);
}

.card-title {
  background-color: #17a2b8;
  color: #ffffff;
}

.card-img-container {
  height: 200px;
  overflow: hidden;
  border-radius: 0;
}

.card-img-top {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
}

.card-img-top:hover {
  transform: scale(1.1);
}

.card-body {
  padding: 1rem;
}

.card-footer {
  background-color: #f8f9fa;
  padding: 0.75rem;
}

.btn {
  border-radius: 2rem;
  padding: 0.5rem 1.5rem;
}

.btn-primary {
  color: #ffffff;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #ffffff;
}

.btn-primary:hover,
.btn-secondary:hover {
  opacity: 0.8;
}

.bg-custom-dark {
  background-color: #004085;
  /* Dark Navy Blue */
}

.text-custom-dark {
  color: #ffffff;
  /* White */
}

.text-custom-light {
  color: #f8f9fa;
  /* Light Gray */
}

.card-text {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
