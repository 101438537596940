
.bursting {
    animation: burst 0.5s forwards;
  }
  
  @keyframes burst {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2) rotate(10deg);
      opacity: 0.8;
    }
    100% {
      transform: scale(0) translate(50vw, 50vh);
      opacity: 0;
    }
  }
  