
/* Common styles */
.social-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 10px;
    color: #fff;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
}

.social-link:hover {
    transform: scale(1.1);
    background-color: #0056b3;
}

/* Profile card styles */
.left-side {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: hidden;
    /* background-color: #ede8f5; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 2s;
    font-family: 'Poppins', sans-serif;
    border-radius: 15px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
}

/* Profile image styles */
/* .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
    object-fit: cover;
    border: 2px solid #fff;
    box-shadow: 0 0 20px rgba(173, 187, 218, 0.2);
} */

/* Profile name styles */
h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

/* Profile role styles */
p {
    font-size: 18px;
    color: black;
    margin-bottom: 20px;
}

/* Social link colors */
.social-link.linkedin {
    background-color: #0e76a8;
}

.social-link.github {
    background-color: black;
}

.social-link.mail {
    background-color: #C71610;
}

/* Loading spinner animation */
@keyframes spin {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

.loading-spinner {
    display: inline-block;
    animation: spin 2s infinite linear;
}

.loading-inner-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    transform-style: preserve-3d;
    animation: spin 2s infinite linear;
    animation-delay: -0.5s;
}

