@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");

/* Colors */
:root {
  --primary-color: black;
  /* Background and primary text color */
  --secondary-color: black;
  /* Hover background color */
  --accent-color: #fff;
  /* Accent color for icons */
  --light-bg-color: #f0f0f0;
  /* Light mode: Background color */
  --light-text-color: black;
  /* Light mode: Primary text color */
  --light-hover-bg-color: #f0f0f0;
  /* Light mode: Hover background color */
  --dark-bg-color: #333;
  /* Dark mode: Background color */
  --dark-text-color: #fff;
  /* Dark mode: Primary text color */
  --dark-hover-bg-color: #555;
  /* Dark mode: Hover background color */
}

/* Example animated class */
.icon {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

/* Add global styles here */

/* Set max width to 1920px for the entire content */
.app-wrapper {
  max-width: 1720px;
  margin: 0 auto;
  /* Center the content horizontally */
  padding: 0 15px;
  /* Add some horizontal padding to the content */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Adjust the height as needed */
}

/* Common styles for both modes */
.nav-link:hover {
  background-color: var(--secondary-color);
  border-radius: 5px;
}

.custom-navbar {
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.slide-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  padding: 15px;
  z-index: 998;
  transition: left 0.3s ease;
}

.close-icon {
  color: var(--accent-color);
}

.icon {
  color: var(--accent-color);
}

/* Critical CSS (inline if necessary) */
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
