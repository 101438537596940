.profile-image {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 10px;
  margin-top: 3px;
  box-shadow: 20px 20px 60px #bebebe,
    -20px -20px 60px #ffffff;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

@media screen and (max-width: 991px) {
  .profile-image {
    width: 250px;
    height: 250px;
  }

}

.profile-image:hover {
  transform: translateY(-5px);
  box-shadow: 15px 15px 30px #bebebe,
    -15px -15px 30px #ffffff;
}

.mobile-profile-card-container {
  height: 65vh;
}

@media (max-width: 991px) {
  .mobile-profile-card-container {
    height: 80vh;
  }
}

.my-name {
  font-family: 'Pacifico', cursive;
  letter-spacing: 2px;
}

.social-link {
  display: inline-block;
  margin: 0 10px;
  padding: 15px;
  border-radius: 50%;
  background-color: #f0f0f0;
  box-shadow: 10px 10px 30px #bebebe,
    -10px -10px 30px #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-link:hover {
  transform: translateY(-3px);
  box-shadow: 7px 7px 20px #bebebe,
    -7px -7px 20px #ffffff;
}

.social-link.linkedin,
.social-link.linkedin:hover {
  background-color: #0077b5;
  color: #fff;
}

.social-link.github,
.social-link.github:hover {
  background-color: #333;
  color: #fff;
}

.social-link.mail,
.social-link.mail:hover {
  background-color: #ff6600;
  color: #fff;
}

.social-link.whatsapp,
.social-link.whatsapp:hover {
  background-color: #25D366;
  color: #fff;
}